import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ActionLink } from '@rsa-digital/evo-shared-components/components/Link';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  heavyFontWeight,
  regularFontWeight,
  semiBoldFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const StyledPanel = styled(SimplePanel)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32;
  ${fonts.paragraph}

  padding: ${spacing(4)} ${spacing(3)};
`;

export const StyledParagraph = styled.p`
  text-align: left;
  margin: 0;
`;

export const StyledSpan = styled.span`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraph}
  `}

  & {
    font-weight: ${heavyFontWeight};
  }
`;

export const StyledSpanHeadingMedium = styled.span`
display: block;

${fonts.headingMedium}
margin-top: ${spacing(1)};

& {
  font-weight: ${semiBoldFontWeight};
  color: ${colors.core03};
  }
`;

export const MonthlyPaymentReminder = styled.p`
  ${fonts.paragraph};
  text-align: left;

  margin: ${spacing(2)} 0 0;

  & {
    font-weight: ${regularFontWeight};
    color: ${colors.neutral01};
  }
`;

export const ActionLinkWithTopMargin = styled(ActionLink)`
  text-align: left;
  & {
    font-weight: ${semiBoldFontWeight};
    color: ${colors.core01};
  }

  margin-top: ${spacing(2)};
`;

export const StyledFlexibleWidthDivider = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(3)};
  hr {
    background-color: ${colors.core01};
  }
`;

export const PayMonthlyPromoHeadingWrapper = styled.div`
  text-align: left;
  ${fonts.paragraphLarge}
  & {
    font-weight: ${semiBoldFontWeight};
  }
  display: flex;
  align-items: center;
  height: ${spacing(4)};
  margin-bottom: ${spacing(1)};

  ${Icon} {
    color: ${colors.cardIcon};
    margin-right: ${spacing(2)};
  }
`;
