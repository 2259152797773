import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';

export const WrapperWithBorder = styled.div`
  border-bottom: solid 1px ${colors.neutral04};
  padding-bottom: ${spacing(2)};

  &:last-child {
    padding-bottom: ${spacing(4)};
  }

  &:not(:last-child) {
    margin-bottom: ${spacing(2)};
  }

  ${mediaQuery.tabletPortrait`
    display:flex;
    justify-content: space-between;
    padding-bottom: ${spacing(3)};

    &:last-child {
      padding-bottom: ${spacing(4)};
    }

    &:not(:last-child) {
      margin-bottom: ${spacing(3)};
    }
  `}
`;

export const Label = styled.p`
  margin: 0;
  ${fonts.paragraph}

  ${mediaQuery.tabletPortrait`
    ${fonts.paragraphLarge}
  `}
`;

export const Date = styled(Label)`
  && {
    font-weight: ${heavyFontWeight};
  }
`;
