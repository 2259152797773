import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h2`
  margin: 0 auto ${spacing(1)} 0;
  ${fonts.headingMedium};
`;

export const Container = styled.div`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(4)};
  `}
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  img:last-of-type {
    margin-right: 0;
  }
`;

export const HeadingAndLogoGridItem = styled(GridItem)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mediaQuery.tabletPortrait`
    align-items: flex-start;
  `}
`;

export const HeadingsWrapper = styled.div`
  width: 100%;

  ${mediaQuery.tabletPortrait`
    width: auto;
  `}
`;

export const Subheading = styled.p`
  ${fonts.paragraph}
  margin: 0 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin: 0;
  `}
`;
