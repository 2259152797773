import { getCoverLevelFromQuoteOptions } from 'apiHelpers/quote/bundleCoverMapping';
import {
  isPlus2kCover,
  isStandardCover,
} from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestionSection/helpers';
import { LifetimeOption, QuoteOptions } from 'state/formData/quoteOptions';

export const isQuoteSummaryDetailsInvalid = (quoteOptions: QuoteOptions): boolean => {
  const coverLevel = getCoverLevelFromQuoteOptions(quoteOptions);

  /*  Details invalid if no cover level is selected */
  return coverLevel === undefined;
};

export const isAdditionalQuestionConfirmed = (quoteOptions: QuoteOptions): boolean => {
  return (
    (isStandardCover(quoteOptions) &&
      quoteOptions.additionalQuestionStandardCover === true) ||
    (isPlus2kCover(quoteOptions) && quoteOptions.additionalQuestionPlus2kCover === true)
  );
};

export const plusOrPremierCoverFeeLimitSelected = (
  quoteOptions: QuoteOptions
): boolean => {
  if (
    quoteOptions.lifetimeOption === LifetimeOption.Total_Annual_Limit &&
    (quoteOptions.plusCoverFeeLimit || quoteOptions.premierCoverFeeLimit !== undefined)
  ) {
    return true;
  }
  return false;
};
