import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  flex-direction: column;
`;

export const Heading = styled.h2`
  margin: 0;
  ${fonts.headingMedium};

  & {
    font-weight: ${semiBoldFontWeight};
    color: ${colors.neutral01};
  }
`;

export const Container = styled.div`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(6)};
  `}
`;

export const QuoteReferenceLabel = styled.p`
  display: inline;
  word-wrap: break-word;
  margin-bottom: ${spacing(1)};
  ${fonts.paragraph};

  ${mediaQuery.tabletPortrait`
    ${fonts.paragraphLarge};
  `}
`;

export const QuoteNumberText = styled(QuoteReferenceLabel)`
  & {
    font-weight: ${semiBoldFontWeight};
  }
`;

export const QuoteReferenceGridItem = styled(GridItem)`
  margin-bottom: ${spacing(1)};
`;
