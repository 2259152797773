import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import mapPaymentPayload from 'apiHelpers/payment/mappings/mapPaymentPayload';
import { StartAnnualPaymentResponse } from 'apiHelpers/payment/paymentResponse';
import useAnnualPayment from 'apiHelpers/payment/useAnnualPayment';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { trackPurchaseEvent } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle } from 'helpers/eventTracking';
import { getSiteUrl, quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  QUOTE_OPTIONS_SESSION_KEY,
  removeSessionData,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import useLoadingState from 'helpers/useLoadingState';
import useResetReduxState from 'helpers/useResetReduxState';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import { useHandleUppAnalytics } from './handleAnalytics';
import { PaymentIframe, PaymentPageWidthWrapper } from './styles';
import { QuoteOptions } from '../../state/formData/quoteOptions';

type UppPaymentProps = {
  quote: CurrentQuote;
};

type LoadingSpinnerProps = {
  csPetGlobalConfig: {
    loading_spinner: {
      switch_payment_spinner_text: string;
      buy_spinner_text: string;
      annual_payment_spinner_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetGlobalConfig {
      loading_spinner {
        switch_payment_spinner_text
        buy_spinner_text
        annual_payment_spinner_text
      }
    }
  }
`;
const UppPayment: React.FC<UppPaymentProps> = ({ quote }) => {
  const {
    csPetGlobalConfig: {
      loading_spinner: {
        switch_payment_spinner_text,
        buy_spinner_text,
        annual_payment_spinner_text,
      },
    },
  } = useStaticQuery<LoadingSpinnerProps>(query);

  const frameRef = useRef<HTMLIFrameElement>(null);
  const { startAnnualPayment, confirmAnnualPayment } = useAnnualPayment();
  const [isSetup, setIsSetup] = useState(false);
  const [switchingFromMonthly, setSwitchingFromMonthly] = useState(false);

  const dispatchError: Dispatch<ErrorAction> = useDispatch();

  const [, updateConfirmationQuote] = useConfirmationQuote();
  const resetReduxState = useResetReduxState();

  const defaultErrorHandling = useDefaultErrorHandling();
  const {
    isLoading: isSettingUpPayment,
    withLoadingState: setupPaymentWithLoading,
  } = useLoadingState();
  const {
    isLoading: isConfirmingPayment,
    withLoadingState: confirmPaymentWithLoading,
  } = useLoadingState();
  const hasSentDataRef = useRef(false);

  const siteUrl = getSiteUrl();

  const handleUppAnalytics = useHandleUppAnalytics();

  const generateConfirmationQuote = (currentQuote: CurrentQuote): CurrentQuote => {
    const quoteOptions: QuoteOptions = JSON.parse(
      retrieveData(QUOTE_OPTIONS_SESSION_KEY) || '{}'
    );
    return {
      ...currentQuote,
      quoteOptions,
      confusedDeeplinkRefValue: retrieveData(CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY),
    };
  };

  const {
    saveAndEmailQuote,
    isSaveInProgress,
    savedQuoteConfirmationModal,
  } = useSaveAndEmailQuote(PageTitle.Payment);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const setupPayment = useCallback(
    (paymentDetails: StartAnnualPaymentResponse | undefined): void => {
      const frameElement = frameRef.current as HTMLIFrameElement;
      const frame = frameElement.contentWindow;

      /* The iframe interaction is hard to test properly with Cypress */
      /* istanbul ignore next */
      window.addEventListener('message', async (event) => {
        if (!frame || event.source !== frame) {
          return;
        }

        if (
          process.env.GATSBY_PAYSAFE_FORM_ACTION &&
          event.origin === new URL(process.env.GATSBY_PAYSAFE_FORM_ACTION).origin
        ) {
          switch (event.data.type) {
            case 'resize':
              frameElement.height = `${event.data.payload.height}px`;
              break;
            case 'scroll':
              scrollToElement(frameElement, -Number(event.data.payload.top));
              break;
            case 'analytics':
              handleUppAnalytics(event.data.payload);
              break;
            case 'emailQuote':
              saveAndEmailQuote();
              return;
            case 'submit':
              frameElement.height = `${window.innerHeight}px`;
              scrollToElement(frameElement, 0);
              break;
            default:
              console.warn(`Unexpected message: ${JSON.stringify(event.data)}`);
          }
        }

        if (siteUrl && event.origin === new URL(siteUrl).origin && paymentDetails) {
          switch (event.data.type) {
            case 'ready':
              if (!hasSentDataRef.current) {
                frame.postMessage(
                  {
                    type: 'submit',
                    payload: mapPaymentPayload(paymentDetails, quote),
                  },
                  siteUrl
                );
                hasSentDataRef.current = true;
              } else {
                /* Due to browsers keeping track of iframe url changes in history, pressing the browser back moves the
                 * url of the iframe back. Therefore, if we recieve a second 'ready' message, then the user must have
                 * used the browser back button, and so we trigger another browser back to get to the page the user was on
                 * before payment.
                 */
                navigate(-1);
              }
              return;
            case 'done':
              await confirmPaymentWithLoading(confirmAnnualPayment);
              trackPurchaseEvent(quote, catBreedsRefData, dogBreedsRefData);
              // eslint-disable-next-line no-case-declarations
              const confirmationQuote = generateConfirmationQuote(quote);
              removeSessionData();
              resetReduxState();
              storeData(
                CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
                confirmationQuote.confusedDeeplinkRefValue ?? ''
              );
              updateConfirmationQuote(confirmationQuote);

              navigate(quoteAndBuyRoutes.confirmation);
              return;
            case 'back':
              navigate(quoteAndBuyRoutes.checkYourDetails);
              return;
            case 'error':
              dispatchError({
                type: ERROR,
                errorType: ErrorType.ANNUAL_PAYMENT_FAILURE,
              });
              return;
            default:
              console.warn(`Unexpected message: ${JSON.stringify(event.data)}`);
          }
        }
      });

      frame?.location.replace(quoteAndBuyRoutes.paymentRedirect);
      setIsSetup(true);
    },
    [
      siteUrl,
      confirmPaymentWithLoading,
      confirmAnnualPayment,
      quote,
      catBreedsRefData,
      dogBreedsRefData,
      resetReduxState,
      updateConfirmationQuote,
      handleUppAnalytics,
      dispatchError,
      saveAndEmailQuote,
    ]
  );

  useEffect(() => {
    if (quote.petInfos && !isSetup && !isSettingUpPayment) {
      setupPaymentWithLoading(async () => {
        try {
          const response = await startAnnualPayment();
          setupPayment(response);
        } catch (error) {
          defaultErrorHandling(error);
        }
      });
    }
  }, [
    defaultErrorHandling,
    isSettingUpPayment,
    isSetup,
    setupPayment,
    setupPaymentWithLoading,
    startAnnualPayment,
    quote,
  ]);

  useEffect(() => {
    const previousPaymentMode = retrieveData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY);
    if (previousPaymentMode === 'MONTHLY') {
      setSwitchingFromMonthly(true);
      storeData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY, 'ANNUAL');
    }
  }, []);

  const settingUpPaymentLoadingMessage = switchingFromMonthly
    ? switch_payment_spinner_text
    : annual_payment_spinner_text;

  return (
    <PaymentPageWidthWrapper>
      {isSettingUpPayment && (
        <LoadingOverlayV2
          loadingMessage={settingUpPaymentLoadingMessage}
          timeDuration={10}
        />
      )}
      {isConfirmingPayment && (
        <LoadingOverlayV2 loadingMessage={buy_spinner_text} timeDuration={10} />
      )}
      {isSaveInProgress && (
        <LoadingOverlayV2
          loadingMessage="Saving quote and sending email..."
          timeDuration={10}
        />
      )}
      {savedQuoteConfirmationModal}
      <PaymentIframe
        ref={frameRef}
        width="100%"
        height={460}
        title="Payment"
        id="paymentFrame"
        name="paymentFrame"
        sandbox="allow-same-origin allow-scripts allow-forms"
      />
    </PaymentPageWidthWrapper>
  );
};

export default UppPayment;
