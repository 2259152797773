import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import {
  Container,
  QuoteNumberText,
  QuoteReferenceGridItem,
  QuoteReferenceLabel,
  StyledGrid,
} from './styles';

export type SectionHeadingWithQuoteNumberProps = {
  heading: string;
  quoteNumberLabel: string;
  quoteNumber: string;
  id: string;
  piiHeading?: boolean;
} & ComponentProps;

const SectionHeadingWithQuoteNumber: React.FC<SectionHeadingWithQuoteNumberProps> = ({
  heading,
  quoteNumberLabel,
  quoteNumber,
  id,
  piiHeading,
  ...props
}) => (
  <Container {...componentProps(props)}>
    <StyledGrid>
      <QuoteReferenceGridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <QuoteReferenceLabel>{quoteNumberLabel} </QuoteReferenceLabel>
        <QuoteNumberText>{quoteNumber}</QuoteNumberText>
      </QuoteReferenceGridItem>
    </StyledGrid>
  </Container>
);

export default SectionHeadingWithQuoteNumber;
