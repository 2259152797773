import { Quote } from 'apiHelpers/quote/quoteResponse';
import mapPolicyMovementDetails from './mapPolicyMovementDetails';
import { StartAnnualPaymentRequest } from '../paymentRequest';

const mapAnnualPaymentRequest = (
  quote: Quote,
  paymentAmount: string
): StartAnnualPaymentRequest => ({
  quoteNumber: quote.policyInfo?.quoteNumber || '',
  policyMovement: mapPolicyMovementDetails(quote),
  paymentDetails: {
    nbxPaymentAmount: paymentAmount,
    nbxCurrencyCode: 'GBP',
    rsaDirectDebitAccountName: '',
    rsaDirectDebitDueDate: '',
    nbxEmail: quote.customerInfo?.email || '',
    rsaPaymentTransactionType: 'NB',
    customDataField: '',
  },
});

export default mapAnnualPaymentRequest;
