import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import React from 'react';
import { Date, Label, WrapperWithBorder } from './styles';

type LabelledDate = {
  label: string;
  date: Date;
};

type LabelledDatesProps = {
  dates: LabelledDate[];
} & ComponentProps;

const LabelledDates: React.FC<LabelledDatesProps> = ({ dates, ...rest }) => (
  <div {...componentProps(rest)}>
    {dates.map((dateData) => (
      <WrapperWithBorder key={dateData.label}>
        <Label>{dateData.label}</Label>
        <Date>{formatLongDateWithDayFirst(dateData.date)}</Date>
      </WrapperWithBorder>
    ))}
  </div>
);

export default LabelledDates;
