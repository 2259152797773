import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import DirectDebit from 'assets/directdebit.svg';
import React from 'react';
import Declaration from 'components/Declaration';
import { PageTitle } from 'helpers/eventTracking';
import useDeclarationContent from './content';
import {
  DeclarationHeading,
  GridWithMargin,
  HeadingAndLogoGridItem,
  HeadingGridItem,
  InfoPanelWithMargin,
  LargeRichText,
  LogoGridItem,
  RichTextWithFonts,
  StyledImage,
} from './styles';

export type DeclarationDetails = {
  hasAgreedToDeclaration: boolean | undefined;
};

type DeclarationSectionProps = {
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  getError: FieldFunction<DeclarationDetails, string | undefined>;
} & ComponentProps;

const DeclarationSection: React.FC<DeclarationSectionProps> = ({
  hasAgreed,
  updateHasAgreed,
  getError,
}) => {
  const content = useDeclarationContent();

  return (
    <>
      <InfoPanelWithMargin data-cy="important-info-panel">
        <RichTextWithFonts
          pageTitle={PageTitle.Payment}
          html={content.importantInformation}
        />
      </InfoPanelWithMargin>
      <Declaration
        id="declaration"
        hasAgreed={hasAgreed}
        updateHasAgreed={updateHasAgreed}
        error={getError('hasAgreedToDeclaration')}
        questionId="hasAgreedToDeclaration"
        questionText={content.questionText}
        pageTitle={PageTitle.Payment}
        gridItemProps={{ desktop: 6, tabletLandscape: 6, tabletPortrait: 6 }}>
        <GridWithMargin alignLeft>
          <HeadingAndLogoGridItem>
            <HeadingGridItem
              mobile={2}
              desktop={4}
              tabletPortrait={4}
              tabletLandscape={4}>
              <DeclarationHeading>{content.guaranteeHeading}</DeclarationHeading>
            </HeadingGridItem>
            <LogoGridItem mobile={2} desktop={2} tabletPortrait={2} tabletLandscape={2}>
              <StyledImage src={DirectDebit} alt="Direct Debit Logo" />
            </LogoGridItem>
          </HeadingAndLogoGridItem>
          <GridItem>
            <LargeRichText pageTitle={PageTitle.Payment} html={content.guaranteeText} />
          </GridItem>
        </GridWithMargin>
      </Declaration>
    </>
  );
};

export default DeclarationSection;
