import { graphql, useStaticQuery } from 'gatsby';
import { PageTitle } from 'helpers/eventTracking';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import {
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';

export type BankPaymentContent = {
  questions: {
    isAccountInName: Question;
    accountHolderName: QuestionWithOptionalPlaceholder;
    accountNumber: QuestionWithOptionalPlaceholder;
    accountSortCode: Question;
    monthlyPaymentDate: QuestionWithPlaceholder;
  };
  errors: {
    bankDetailsNotFound: string;
    paymentFailure: string;
  };
  labels: {
    heading: string;
    bankDetailsConfirmed: string;
  };
};

type csBankPaymentQuestions = {
  csPetMonthlyPayment: {
    bank_details: {
      heading: string;
      is_account_in_name: CsQuestion;
      account_holder_name: CsQuestionWithPlaceholder;
      account_number: CsQuestionWithPlaceholder;
      sort_code: CsQuestion;
      monthly_payment_date: CsQuestionWithPlaceholder;
      bank_details_confirmed_text: string;
      error_messages: {
        details_not_found: string;
        payment_fails: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetMonthlyPayment {
      bank_details {
        heading
        is_account_in_name {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
        account_holder_name {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
            placeholder
          }
        }
        account_number {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
            placeholder
          }
        }
        sort_code {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
        monthly_payment_date {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
            placeholder
          }
        }
        bank_details_confirmed_text
        error_messages {
          details_not_found
          payment_fails
        }
      }
    }
  }
`;

const useBankPaymentContent = (): BankPaymentContent => {
  const csContent = useStaticQuery<csBankPaymentQuestions>(query).csPetMonthlyPayment
    .bank_details;
  const {
    processQuestion,
    processQuestionWithPlaceholder,
    processQuestionWithOptionalPlaceholder,
  } = useQuestionProcessor(PageTitle.Payment);

  return {
    questions: {
      isAccountInName: processQuestion(csContent.is_account_in_name),
      accountHolderName: processQuestionWithOptionalPlaceholder(
        csContent.account_holder_name
      ),
      accountNumber: processQuestionWithOptionalPlaceholder(csContent.account_number),
      accountSortCode: processQuestion(csContent.sort_code),
      monthlyPaymentDate: processQuestionWithPlaceholder(csContent.monthly_payment_date),
    },
    errors: {
      bankDetailsNotFound: csContent.error_messages.details_not_found,
      paymentFailure: csContent.error_messages.payment_fails,
    },
    labels: {
      heading: csContent.heading,
      bankDetailsConfirmed: csContent.bank_details_confirmed_text,
    },
  };
};

export default useBankPaymentContent;
