import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanel } from 'components/StatusPanel';

export const InfoPanelWithMargin = styled(InfoPanel)`
  margin-top: 0;

  && {
    padding: ${spacing(3)} ${spacing(3)} ${spacing(3)} ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      padding-left: ${spacing(3)}
    `}

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(2)}
    `}
  }

  ${RichText} > :first-child {
    margin-top: 0;
  }
`;

export const RichTextWithFonts = styled(RichTextWithModal)`
  && {
    h3 {
      ${fonts.headingXSmall}

      ${mediaQuery.tabletLandscape`
        & {
          font-weight: ${heavyFontWeight}
        }
      `}
    }

    p {
      ${fonts.paragraphLarge}
      margin-top: ${spacing(0)};
    }
  }
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-top: ${spacing(4)};
    margin-bottom: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(2)};
    `}
  }
`;

export const DeclarationHeading = styled.h3`
  ${fonts.headingSmall}
  margin: 0;
`;

export const StyledImage = styled.img`
  height: ${spacing(4)};
`;

export const LargeRichText = styled(RichTextWithModal)`
  p,
  && li {
    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(3)};
      margin-bottom: ${spacing(3)};
    `}
  }
`;

export const DeclarationLogosWrapper = styled.div`
  display: flex;
  flex-direction: row;

  img:last-of-type {
    margin-right: 0;
  }
`;

export const HeadingAndLogoGridItem = styled(GridItem)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mediaQuery.tabletPortrait`
    align-items: flex-start;
  `}
`;

export const LogoGridItem = styled(GridItem)`
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  margin-right: 0;
`;

export const HeadingGridItem = styled(GridItem)`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: 0;
`;
