import paymentClient from 'apiHelpers/paymentClient';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { poundsToPence } from 'helpers/poundsHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { RootState } from 'state/createStore';
import { UPDATE_QUOTE, UpdateQuoteAction } from 'state/quote/quote';
import mapAnnualPaymentRequest from './mappings/mapAnnualPaymentRequest';
import { StartAnnualPaymentResponse } from './paymentResponse';

const useAnnualPayment = (): {
  startAnnualPayment: () => Promise<StartAnnualPaymentResponse | undefined>;
  confirmAnnualPayment: () => Promise<void>;
} => {
  const quote = useSelector((state: RootState) => state.quote);
  const currentQuote = useCurrentQuote();

  const dispatch = useDispatch<Dispatch<UpdateQuoteAction>>();

  const startAnnualPayment = async (): Promise<
    StartAnnualPaymentResponse | undefined
  > => {
    if (quote) {
      const request = mapAnnualPaymentRequest(
        quote,
        poundsToPence(currentQuote.price?.annualPrice.total)
      );
      const response = await paymentClient.startAnnualPayment(request);
      dispatch({
        type: UPDATE_QUOTE,
        quote: {
          ...quote,
          policyInfo: {
            ...quote.policyInfo,
            quoteMovementUpdateDate: response.policyMovementUpdateDate,
          },
        },
      });
      return response;
    }
    return undefined;
  };

  const confirmAnnualPayment = async (): Promise<void> => {
    if (quote) {
      await paymentClient.confirmAnnualPayment({
        policyMovementUpdateDate: quote.policyInfo.quoteMovementUpdateDate || '',
        quoteNumber: quote.policyInfo.quoteNumber || '',
      });
    }
  };

  return {
    startAnnualPayment,
    confirmAnnualPayment,
  };
};

export default useAnnualPayment;
