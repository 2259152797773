import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import PolicyDocuments from 'components/PolicyDocuments';
import { PageTitle } from 'helpers/eventTracking';
import { QuoteOptions } from 'state/formData/quoteOptions';
import {
  PolicyDocumentsGridItem,
  StyledHeading,
  StyledPageWidthContainer,
  StyledRichText,
} from './styles';

type PolicyDocumentsContent = {
  csPetPolicyDocumentsV2: {
    heading: string;
    summary: string;
  };
};

const query = graphql`
  query {
    csPetPolicyDocumentsV2 {
      heading
      summary
    }
  }
`;

type PolicyDocumentsSectionProps = {
  quoteOptions: QuoteOptions;
};

const PolicyDocumentsSection: React.FC<PolicyDocumentsSectionProps> = ({
  quoteOptions,
}) => {
  const { heading, summary } = useStaticQuery<PolicyDocumentsContent>(
    query
  ).csPetPolicyDocumentsV2;

  const sectionId = 'policy-documents-section-id';
  return (
    <StyledPageWidthContainer>
      <Grid as="section" alignLeft aria-labelledby={sectionId}>
        <GridItem>
          <StyledHeading id={sectionId}>{heading}</StyledHeading>
        </GridItem>
        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6} mobile={4}>
          {summary && <StyledRichText pageTitle={PageTitle.Payment} html={summary} />}
        </GridItem>
        <PolicyDocumentsGridItem>
          <PolicyDocuments quoteOptions={quoteOptions} />
        </PolicyDocumentsGridItem>
      </Grid>
    </StyledPageWidthContainer>
  );
};

export default PolicyDocumentsSection;
