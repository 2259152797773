import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import PredefinedPanel from '@rsa-digital/evo-shared-components/components/Panel/PredefinedPanel';
import { PanelOptions } from '@rsa-digital/evo-shared-components/components/Panel/PredefinedPanel/predefinedStyles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, {
  spacingWithBorder,
} from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const RichTextWithTopMargin = styled(RichTextWithModal)`
  margin: ${spacing(4)} ${spacing(2)} 0;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(4)} ${spacing(4)} 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)} 0 0 ${spacing(8)};
  `}
`;

export const StyledPredefinedPanel = styled(PredefinedPanel)`
  overflow: hidden;
`;

export const QuestionPanel = styled(PredefinedPanel)`
  && {
    box-shadow: none;
  }
  border: ${({ panelType }) => (panelType === PanelOptions.NONE ? 'none' : '')};

  margin: -1px;
`;

export const FlexibleWidthDividerWithMargin = styled(FlexibleWidthDivider)`
  margin-bottom: ${spacingWithBorder(4, 1)};
`;

export const GridWithVerticalMargin = styled(Grid)<{ error?: boolean }>`
  ${({ error }) =>
    error
      ? css`
          && {
            margin-bottom: ${spacing(4)};

            ${mediaQuery.tabletPortrait`
              margin-top: ${spacing(4)};
              margin-bottom: ${spacing(3)};
            `};

            ${mediaQuery.tabletLandscape`
              margin-bottom: ${spacing(4)};
            `};
          }
        `
      : css`
          && {
            margin-bottom: ${spacing(4)};

            ${mediaQuery.tabletLandscape`
              margin-bottom: ${spacing(6)};
            `};
          }
        `};
`;

export const DivWithHorizontalPadding = styled.div`
  padding: 0 ${spacing(2)} 0;

  ${mediaQuery.tabletPortrait`
    padding: 0 ${spacing(4)} 0;
  `}

  ${mediaQuery.tabletLandscape`
    padding: 0 0 0 ${spacing(8)};
  `}
`;

export const BooleanInputWrapper = styled.div`
  margin: 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin: 0 ${spacing(-4)} 0 ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: 0 ${spacing(-8)} 0 ${spacing(8)};
  `}
`;

export const LargeTextLabelWithMargin = styled.label`
  ${fonts.paragraphLarge};
  margin: 0 ${spacing(2)} ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin: 0 ${spacing(6)} ${spacing(1)} ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: 0 0 ${spacing(1)} ${spacing(8)};
  `}

  display: inline-block;
`;

export const QuestionFieldWithoutBottomMargin = styled(QuestionField)`
  margin-bottom: 0;
`;
