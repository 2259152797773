import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { regularFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import ConfirmationBox from 'components/ConfirmationBox';
import RichTextWithModal from 'components/RichTextWithModal';
import LabelledDates from './LabelledDates';

export const StyledGrid = styled(Grid)`
  flex-direction: column;
`;

export const StyledConfirmationBox = styled(ConfirmationBox)`
  margin-top: ${spacing(2)};
  padding: ${spacing(3)};
`;

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin: ${spacing(3)} 0 0;

  p {
    ${mediaQuery.tabletPortrait`
      ${fonts.paragraphSmall};
    `}

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraph};
    `}

    & {
      font-weight: ${regularFontWeight};
    }
  }
`;

export const PricePanelHeading = styled.h2`
  ${fonts.paragraphLarge}
  margin: ${spacing(4)} 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    ${fonts.headingSmall}
    margin: ${spacing(6)} 0 ${spacing(4)};
  `}
  
  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
  `}
`;

export const LabelledDatesWithMargin = styled(LabelledDates)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const FlexibleWidthDividerWithTopMargin = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(1)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: 0;
  `}
`;
