import {
  Grid,
  GridItem,
  GridItemProps,
} from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle } from 'helpers/eventTracking';
import { QuestionFieldWithoutBottomMargin } from './styles';

export type DeclarationDetails = {
  hasAgreedToDeclaration: boolean | undefined;
};

type DeclarationProps = {
  id: string;
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  error?: string;
  questionId: string;
  questionText: string;
  gridItemProps?: GridItemProps;
  disclaimerText?: string;
  pageTitle: PageTitle;
} & ComponentProps;

const Declaration: React.FC<DeclarationProps> = ({
  id,
  hasAgreed,
  updateHasAgreed,
  error,
  questionId,
  questionText,
  children,
  gridItemProps,
  disclaimerText,
  pageTitle,
  ...rest
}) => {
  return (
    <Grid alignLeft>
      <GridItem>
        <div {...componentProps(rest)} id={id}>
          {children}
          <Grid alignLeft>
            <GridItem desktop={6} tabletPortrait={6}>
              <QuestionFieldWithoutBottomMargin
                question={{ questionText }}
                errorText={error}>
                <BooleanRadioInput
                  id={questionId}
                  value={hasAgreed}
                  onChange={updateHasAgreed}
                  aria-describedby={`${questionId}-error`}
                />
              </QuestionFieldWithoutBottomMargin>
              {disclaimerText && (
                <GridItem>
                  <RichTextWithModal
                    data-cy={`${id}-disclaimer-text`}
                    html={disclaimerText}
                    pageTitle={pageTitle}
                  />
                </GridItem>
              )}
            </GridItem>
          </Grid>
        </div>
      </GridItem>
    </Grid>
  );
};

export default Declaration;
