import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  ActionLinkWithTopMargin,
  MonthlyPaymentReminder,
  PayMonthlyPromoHeadingWrapper,
  StyledFlexibleWidthDivider,
  StyledPanel,
  StyledParagraph,
  StyledSpan,
  StyledSpanHeadingMedium,
} from './styles';

type AnnualPaymentContent = {
  csPetAnnualPayment: {
    payment_summary: {
      pay_monthly_promo_heading: string;
      pay_monthly_promo_text: string;
    };
  };
  csPetSummaryOfCoverInstallmentInfo: {
    annual_installment_text: string;
    monthly_installment_text: string;
  };
  csPetSummaryOfCover: {
    header_row: {
      monthly_payment_reminder_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetAnnualPayment {
      payment_summary {
        pay_monthly_promo_heading
        pay_monthly_promo_text
      }
    }
    csPetSummaryOfCoverInstallmentInfo {
      annual_installment_text
      monthly_installment_text
    }
    csPetSummaryOfCover {
      header_row {
        monthly_payment_reminder_text
      }
    }
  }
`;

type PricePanelProps = {
  isAnnualPayment: boolean;
  summaryText: string;
  price: string;
  link?: {
    text: string;
    screenreaderText?: string;
    onClick: React.MouseEventHandler;
  };
  linkDisabled?: boolean;
} & ComponentProps;

const PricePanel: React.FC<PricePanelProps> = ({
  isAnnualPayment,
  summaryText,
  price,
  link,
  linkDisabled,
  ...props
}) => {
  const {
    csPetAnnualPayment: {
      payment_summary: { pay_monthly_promo_heading, pay_monthly_promo_text },
    },
    csPetSummaryOfCover: {
      header_row: { monthly_payment_reminder_text },
    },
    csPetSummaryOfCoverInstallmentInfo: {
      monthly_installment_text,
      annual_installment_text,
    },
  } = useStaticQuery<AnnualPaymentContent>(query);

  return (
    <StyledPanel {...componentProps(props)} borderShadows={{ displayOutset: true }}>
      <StyledParagraph aria-live="polite" aria-atomic="true">
        <StyledSpan>
          {isAnnualPayment ? annual_installment_text : monthly_installment_text}
        </StyledSpan>
        <StyledSpanHeadingMedium>{price}</StyledSpanHeadingMedium>
      </StyledParagraph>
      <MonthlyPaymentReminder>{monthly_payment_reminder_text}</MonthlyPaymentReminder>
      {link && (
        <ActionLinkWithTopMargin
          onClick={link.onClick}
          aria-label={link.screenreaderText}
          disabled={linkDisabled}>
          {link.text}
        </ActionLinkWithTopMargin>
      )}
      {isAnnualPayment && (
        <div>
          <StyledFlexibleWidthDivider />
          <PayMonthlyPromoHeadingWrapper>
            <Icon name="information" size="medium" />
            <span data-cy="payMonthlyPromoHeading">{pay_monthly_promo_heading}</span>
          </PayMonthlyPromoHeadingWrapper>
          <RichText data-cy="payMonthlyPromoText" html={pay_monthly_promo_text} />
        </div>
      )}
    </StyledPanel>
  );
};

export default PricePanel;
