import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  regularFontWeight,
  semiBoldFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import SectionHeading from 'forms/SectionHeading';

export const Container = styled(PageWidthContainer)`
  background-color: ${colors.neutral05};
`;

export const StyledGrid = styled(Grid)`
  margin-top: ${spacing(4)};
  padding-bottom: ${spacing(5)};

  &&& a {
    margin-top: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
      font-weight: ${semiBoldFontWeight};
    `}
  }
`;

export const StyledSectionHeading = styled(SectionHeading)`
  h2 {
    ${fonts.headingSmall}
  }

  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(2)};
`;

export const StyledPageWidthContainer = styled(PageWidthContainer)`
  background-color: ${colors.neutral05};
  padding-top: ${spacing(4)};
  padding-bottom: ${spacing(5)};

  ${mediaQuery.tabletPortrait`
    padding-top: ${spacing(6)};
    padding-bottom: ${spacing(7)};
  `}
`;

export const StyledRichText = styled(RichTextWithModal)`
  ${RichText} {
    p {
      margin-bottom: ${spacing(1)};

      & {
        font-weight: ${regularFontWeight};
      }
    }
  }

  margin-bottom: ${spacing(5)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
  `}
`;

export const StyledHeading = styled.h3`
  ${fonts.headingSmall}
  margin-top: 0;
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(3)};
  `}
`;

export const PolicyDocumentsGridItem = styled(GridItem)`
  a:first-child {
    margin-top: 0;
  }
`;
