import { graphql, useStaticQuery } from 'gatsby';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { getProductFromQuoteOptions, Product } from './productHelpers';

export type DocumentLink = {
  url: string;
  text: string;
  screenreader_text: string | null;
  open_in_new_tab: boolean;
};

type CsPolicyDocumentsContent = {
  csPetPolicyDocumentsV2: {
    essential: DocumentLink;
    standard: DocumentLink;
    plus: DocumentLink;
    premier: DocumentLink;
  };
};

const query = graphql`
  query {
    csPetPolicyDocumentsV2 {
      essential {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      standard {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      plus {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      premier {
        url
        text
        screenreader_text
        open_in_new_tab
      }
    }
  }
`;

export const usePolicyDocumentLink = (
  quoteOptions: QuoteOptions
): DocumentLink | undefined => {
  const csData = useStaticQuery<CsPolicyDocumentsContent>(query);
  const product = getProductFromQuoteOptions(quoteOptions);

  switch (product) {
    case Product.Essential:
      return csData.csPetPolicyDocumentsV2.essential;
    case Product.Standard:
      return csData.csPetPolicyDocumentsV2.standard;
    case Product.Plus:
      return csData.csPetPolicyDocumentsV2.plus;
    case Product.Premier:
      return csData.csPetPolicyDocumentsV2.premier;
    default:
      return undefined;
  }
};
