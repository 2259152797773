import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { DeclarationDetails } from '.';

type csDeclarationErrorMessages = {
  csPetMonthlyPayment: {
    declaration: {
      direct_debit_guarantee_agreement: {
        error_messages: {
          missing: string;
          no_selected: string;
        };
      };
    };
  };
};

const query = graphql`
  query {
    csPetMonthlyPayment {
      declaration {
        direct_debit_guarantee_agreement {
          error_messages {
            missing
            no_selected
          }
        }
      }
    }
  }
`;

const useDeclarationRules = (): ValidationRules<DeclarationDetails> => {
  const { missing, no_selected } = useStaticQuery<csDeclarationErrorMessages>(
    query
  ).csPetMonthlyPayment.declaration.direct_debit_guarantee_agreement.error_messages;

  return {
    hasAgreedToDeclaration: [
      required(missing),
      {
        validityCondition: (value) => value !== false,
        errorMessage: no_selected,
      },
    ],
  };
};

export default useDeclarationRules;
