import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { sortCodeValueToString } from '@rsa-digital/evo-shared-components/helpers/sortCodes';
import useMonthlyPayment from 'apiHelpers/payment/useMonthlyPayment';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import { AxiosError } from 'axios';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import FormFooter from 'components/FormFooter';
import BankPayment from 'components/Payment/MonthlyPaymentPage/BankPayment';
import PolicyDocumentsSection from 'components/Payment/PolicyDocumentsSection';
import { trackPurchaseEvent } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackAPIError,
  trackFieldError,
  trackFormSubmissionErrors,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  removeSessionData,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useLoadingState from 'helpers/useLoadingState';
import useResetReduxState from 'helpers/useResetReduxState';
import { initialQuoteOptions } from 'state/formData/quoteOptions';
import { ConfirmationQuote, useConfirmationQuote } from 'state/quote/confirmationQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import useBankDetailsRules, { DirectDebitDetails } from './BankPayment/validation';
import DeclarationSection, { DeclarationDetails } from './DeclarationSection';
import useDeclarationRules from './DeclarationSection/validation';
import CancellationSignpost from '../CancellationSignpost';
import PaymentSummarySection from '../PaymentSummarySection';

type MonthlyPaymentPageContent = {
  csPetMonthlyPayment: {
    next_button_text: string;
  };
  csPetGlobalConfig: {
    loading_spinner: {
      email_spinner_text: string;
      switch_payment_spinner_text: string;
      buy_spinner_text: string;
      monthly_payment_spinner_text: string;
      bank_details_spinner_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetMonthlyPayment {
      next_button_text
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        switch_payment_spinner_text
        buy_spinner_text
        monthly_payment_spinner_text
        bank_details_spinner_text
      }
    }
  }
`;

export type MonthlyPaymentState = DirectDebitDetails &
  DeclarationDetails & {
    collectionAmount?: number;
  };

const MonthlyPaymentPage: React.FC = () => {
  const {
    csPetMonthlyPayment: { next_button_text },
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text,
        switch_payment_spinner_text,
        buy_spinner_text,
        monthly_payment_spinner_text,
        bank_details_spinner_text,
      },
    },
  } = useStaticQuery<MonthlyPaymentPageContent>(query);

  const [, updateConfirmationQuote] = useConfirmationQuote();

  const {
    startMonthlyPayment,
    confirmMonthlyPayment,
    validateBankDetails,
  } = useMonthlyPayment();

  const [isSetup, setIsSetup] = useState(false);
  const [switchingFromAnnual, setSwitchingFromAnnual] = useState(false);

  const {
    isLoading: isSettingUpPayment,
    withLoadingState: setupPaymentWithLoading,
  } = useLoadingState();
  const {
    isLoading: isConfirmingPayment,
    withLoadingState: confirmPaymentWithLoading,
  } = useLoadingState();
  const {
    isLoading: isConfirmingBankDetails,
    withLoadingState: validateBankDetailsWithLoadingState,
  } = useLoadingState();

  const {
    saveAndEmailQuote,
    savedQuoteConfirmationModal,
    isSaveInProgress,
  } = useSaveAndEmailQuote(PageTitle.Payment);
  const defaultErrorHandling = useDefaultErrorHandling();

  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;

  const resetReduxState = useResetReduxState();

  const [paymentState, setPaymentState] = useState<MonthlyPaymentState>({
    isAccountInName: undefined,
    accountHolderName: '',
    accountNumber: '',
    accountSortCode: {},
    monthlyPaymentDate: '01',
    bankDetails: { status: 'NONE' },
    paymentError: false,
    hasAgreedToDeclaration: undefined,
  });

  const directDebitRules = useBankDetailsRules();
  const declarationRules = useDeclarationRules();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  const { getError, validateOnSubmit, showValidation } = useValidation(
    paymentState,
    {
      ...directDebitRules,
      ...declarationRules,
    },
    trackFieldError
  );

  const updatePaymentState = useCallback(
    (update: Partial<MonthlyPaymentState>) =>
      setPaymentState((state) => ({
        ...state,
        ...update,
      })),
    [setPaymentState]
  );

  const {
    accountNumber: accountNumberRules,
    accountSortCode: sortCodeRules,
  } = useBankDetailsRules();

  const tryValidateBankDetails = async (): Promise<void> => {
    if (
      paymentState.bankDetails.status === 'NONE' &&
      accountNumberRules?.every((rule) =>
        rule.validityCondition(paymentState.accountNumber, paymentState)
      ) &&
      sortCodeRules?.every((rule) =>
        rule.validityCondition(paymentState.accountSortCode, paymentState)
      )
    ) {
      const request = {
        accountNumber: paymentState.accountNumber,
        sortCode: sortCodeValueToString(paymentState.accountSortCode),
        quoteNumber: quote.policyInfo?.quoteNumber || '',
      };
      const searchResult = await validateBankDetailsWithLoadingState(() =>
        validateBankDetails(request)
      );
      updatePaymentState({
        bankDetails: searchResult,
      });
    }

    if (paymentState.bankDetails.status === 'FAILURE') {
      scrollAndFocusInput('accountNumber');
      trackFormSubmissionErrors({ error: 'accountNumber,accountSortCode' });
    }
  };

  const onSubmitAndMoveNext = useCallback(
    async (details: MonthlyPaymentState): Promise<void> => {
      if (paymentState.bankDetails.status === 'FAILURE') {
        scrollToElement('bank-details-error', 20);
        return;
      }

      if (isConfirmingPayment) {
        return;
      }

      try {
        updatePaymentState({
          paymentError: false,
        });
        await confirmPaymentWithLoading(() => confirmMonthlyPayment(details));
        const confirmationQuote: ConfirmationQuote = {
          ...quote,
          monthlyPaymentDate: parseInt(paymentState.monthlyPaymentDate, 10),
          confusedDeeplinkRefValue: retrieveData(CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY),
        };
        trackPurchaseEvent(confirmationQuote, catBreedsRefData, dogBreedsRefData);
        removeSessionData();
        resetReduxState();
        storeData(
          CONFUSED_DEEPLINK_REF_VALUE_SESSION_KEY,
          confirmationQuote.confusedDeeplinkRefValue ?? ''
        );
        updateConfirmationQuote(confirmationQuote);
        navigate(quoteAndBuyRoutes.confirmation);
      } catch (error) {
        trackAPIError(error as AxiosError);
        updatePaymentState({
          paymentError: true,
        });
        scrollToElement('payment-error', 20);
      }
    },
    [
      paymentState.bankDetails.status,
      paymentState.monthlyPaymentDate,
      isConfirmingPayment,
      updatePaymentState,
      confirmPaymentWithLoading,
      quote,
      catBreedsRefData,
      dogBreedsRefData,
      resetReduxState,
      updateConfirmationQuote,
      confirmMonthlyPayment,
    ]
  );

  useEffect(() => {
    if (quote.petInfos && !isSetup && !isSettingUpPayment) {
      setupPaymentWithLoading(async () => {
        try {
          const response = await startMonthlyPayment();
          updatePaymentState({
            collectionAmount: response,
          });
          setIsSetup(true);
        } catch (error) {
          defaultErrorHandling(error as AxiosError);
        }
      });
    }
  }, [
    defaultErrorHandling,
    isSettingUpPayment,
    isSetup,
    setupPaymentWithLoading,
    startMonthlyPayment,
    updatePaymentState,
    quote.petInfos,
  ]);

  useEffect(() => {
    const previousPaymentMode = retrieveData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY);
    if (previousPaymentMode === 'ANNUAL') {
      setSwitchingFromAnnual(true);
      storeData(PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY, 'MONTHLY');
    }
  }, []);

  useEffect(() => {
    // Make payment and redirect to Confirmation page
    if (paymentState.bankDetails.status === 'SUCCESS') {
      onSubmitAndMoveNext(paymentState);
    }
    if (paymentState.bankDetails.status === 'FAILURE') {
      scrollToElement('accountNumber-error', 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentState.bankDetails.status]);

  const settingUpPaymentLoadingMessage = switchingFromAnnual
    ? switch_payment_spinner_text
    : monthly_payment_spinner_text;

  return (
    <>
      {isSettingUpPayment && (
        <LoadingOverlayV2
          id="preparing_payment"
          loadingMessage={settingUpPaymentLoadingMessage}
          timeDuration={12}
        />
      )}
      {isConfirmingPayment && (
        <LoadingOverlayV2 loadingMessage={buy_spinner_text} timeDuration={10} />
      )}
      <PaymentSummarySection
        isAnnualPayment={false}
        switchPaymentType={async () => {
          updateQuoteOptions({
            isAnnualPayment: !quote.quoteOptions.isAnnualPayment,
          });
        }}
        quote={quote}
        linkDisabled={isSettingUpPayment}
      />
      <PolicyDocumentsSection quoteOptions={quote.quoteOptions} />
      <Grid alignLeft>
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8} mobile={4}>
          {isSaveInProgress && (
            <LoadingOverlayV2 loadingMessage={email_spinner_text} timeDuration={10} />
          )}
          {isConfirmingBankDetails && (
            <LoadingOverlayV2
              id="confirming_bank_details"
              loadingMessage={bank_details_spinner_text}
              timeDuration={10}
            />
          )}
          <form
            onSubmit={validateOnSubmit(() => {
              tryValidateBankDetails();
            }, scrollAndTrackError)}>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6} mobile={4}>
              <BankPayment
                details={paymentState}
                dateSelectionDisabled={isSettingUpPayment}
                updateCollectionAmount={(collectionAmount) =>
                  updatePaymentState({
                    collectionAmount,
                  })
                }
                updateDetails={updatePaymentState}
                getError={getError}
                showValidation={showValidation}
              />
              <DeclarationSection
                hasAgreed={paymentState.hasAgreedToDeclaration}
                updateHasAgreed={(hasAgreedToDeclaration) => {
                  updatePaymentState({
                    hasAgreedToDeclaration,
                  });
                  showValidation('hasAgreedToDeclaration');
                }}
                getError={getError}
              />
              <CancellationSignpost />
            </GridItem>
            <FormFooter
              backButton={{
                onClick: () => {
                  trackTextButtonClick(PageTitle.Payment, 'Back');
                  navigate(quoteAndBuyRoutes.checkYourDetails);
                },
              }}
              moveNextButton={{
                text: next_button_text,
                onClick: () => trackTextButtonClick(PageTitle.Payment, 'Buy now'),
              }}
              saveButton={{
                onClick: () => {
                  if (isQuoteOptionSelectionValid(quoteOptions)) {
                    saveAndEmailQuote();
                  }
                },
              }}
              pageTitle={PageTitle.Payment}
            />
          </form>
        </GridItem>
        {savedQuoteConfirmationModal}
      </Grid>
    </>
  );
};

export default MonthlyPaymentPage;
