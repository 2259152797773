import { SortCodeValue } from '@rsa-digital/evo-shared-components/components/Form/SortCodeInput';
import {
  lengthGreaterOrEqualTo,
  required,
  requiredSortCodeValue,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { BankDetails } from 'apiHelpers/payment/paymentResponse';
import { graphql, useStaticQuery } from 'gatsby';
import { CsErrorsMissingOnly } from 'types/contentStack';

type csBankPaymentQuestionsErrorMessages = {
  csPetMonthlyPayment: {
    bank_details: {
      is_account_in_name: {
        error_messages: {
          missing: string;
          no_selected: string;
        };
      };
      account_holder_name: CsErrorsMissingOnly;
      account_number: {
        error_messages: {
          missing: string;
          too_short: string;
        };
      };
      sort_code: CsErrorsMissingOnly;
      monthly_payment_date: CsErrorsMissingOnly;
      error_messages: {
        details_not_found: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetMonthlyPayment {
      bank_details {
        is_account_in_name {
          error_messages {
            missing
            no_selected
          }
        }
        account_holder_name {
          error_messages {
            missing
          }
        }
        account_number {
          error_messages {
            missing
            too_short
          }
        }
        sort_code {
          error_messages {
            missing
          }
        }
        monthly_payment_date {
          error_messages {
            missing
          }
        }
        error_messages {
          details_not_found
        }
      }
    }
  }
`;

export type BankDetailsSearch =
  | { status: 'NONE' }
  | { status: 'FAILURE' }
  | {
      status: 'SUCCESS';
      data: BankDetails | undefined;
    };

export type DirectDebitDetails = {
  isAccountInName: boolean | undefined;
  accountHolderName: string;
  accountNumber: string;
  accountSortCode: SortCodeValue;
  monthlyPaymentDate: string;
  bankDetails: BankDetailsSearch;
  paymentError: boolean;
};

const useBankDetailsRules = (): ValidationRules<DirectDebitDetails> => {
  const errorMessages = useStaticQuery<csBankPaymentQuestionsErrorMessages>(query)
    .csPetMonthlyPayment.bank_details;

  return {
    isAccountInName: [
      required(errorMessages.is_account_in_name.error_messages.missing),
      {
        validityCondition: (value) => !!value,
        errorMessage: errorMessages.is_account_in_name.error_messages.no_selected,
      },
    ],
    accountHolderName: [
      required(errorMessages.account_holder_name.error_messages.missing),
    ],
    accountNumber: [
      required(errorMessages.account_number.error_messages.missing),
      lengthGreaterOrEqualTo(8, errorMessages.account_number.error_messages.too_short),
    ],
    accountSortCode: [
      requiredSortCodeValue(errorMessages.sort_code.error_messages.missing),
    ],
    monthlyPaymentDate: [
      required(errorMessages.monthly_payment_date.error_messages.missing),
    ],
  };
};

export default useBankDetailsRules;
