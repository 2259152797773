import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackTextButtonClick,
  trackTooltipToggle,
} from 'helpers/eventTracking';
import { useUpdateQuoteOptions } from 'helpers/useCurrentQuote';

type AnalyticsEvent<EventType extends string, Data> = {
  type: EventType;
  data: Data;
};

type AnalyticsPayload =
  | AnalyticsEvent<'buttonClick', { buttonText: string }>
  | AnalyticsEvent<'formFieldEntry', { label: string }>
  | AnalyticsEvent<'dropdownSelector', { question: string }>
  | AnalyticsEvent<'primarySelector', { question: string }>
  | AnalyticsEvent<'tooltipClick', { label: string }>
  | AnalyticsEvent<'paymentCardType', { partnershipCard: boolean }>;

export const useHandleUppAnalytics = (): ((payload: AnalyticsPayload) => void) => {
  const updateQuoteOptions = useUpdateQuoteOptions();

  return (payload: AnalyticsPayload): void => {
    /* istanbul ignore next */
    switch (payload.type) {
      case 'buttonClick':
        trackTextButtonClick(PageTitle.Payment, payload.data.buttonText);
        break;
      case 'formFieldEntry':
        trackFormTextFieldFocus(payload.data.label)();
        break;
      case 'dropdownSelector':
        trackFormDropdownSelect(payload.data.question);
        break;
      case 'primarySelector':
        trackFormDropdownFocus(payload.data.question, PageTitle.Payment)();
        break;
      case 'tooltipClick':
        trackTooltipToggle(PageTitle.Payment, payload.data.label);
        break;
      case 'paymentCardType':
        updateQuoteOptions({ partnershipCard: payload.data.partnershipCard });
        break;
      default:
        console.warn('Received unexpected UPP postMessage analytics payload:', payload);
    }
  };
};
