import { sortCodeValueToString } from '@rsa-digital/evo-shared-components/helpers/sortCodes';
import {
  getBundleFromQuote,
  getCoverLevelFromQuote,
} from 'apiHelpers/quote/bundleCoverMapping';
import { Quote } from 'apiHelpers/quote/quoteResponse';
import { MonthlyPaymentState } from 'components/Payment/MonthlyPaymentPage';
import { CORE_COVER } from 'helpers/businessConstants';
import { MonthlyConfirmationPolicyMovementDetails } from '../paymentRequest';

const mapMonthlyConfirmationPolicyMovementDetails = (
  quote: Quote,
  paymentDetails: MonthlyPaymentState
): MonthlyConfirmationPolicyMovementDetails => {
  const coverLevel = getCoverLevelFromQuote(quote);
  const bundle = getBundleFromQuote(coverLevel, quote);

  const { customerInfo, parentCover, policyInfo, productId } = quote;

  const selectedCovers = parentCover.filter((parentCoverCode) =>
    bundle?.covers?.some(
      (cover) =>
        parentCoverCode !== CORE_COVER &&
        (cover.coverCode === parentCoverCode || cover.cover.name === parentCoverCode) &&
        cover.selected
    )
  );

  const petCovers = selectedCovers.map((coverSection) => ({
    coverStartDate: new Date(quote.policyInfo.coverStartDate).toISOString(),
    coverSection,
    productId,
  }));

  return {
    policyMovementUpdateDate: policyInfo?.quoteMovementUpdateDate ?? '',
    collection: {
      collectionAmount: paymentDetails.collectionAmount || 0,
      bankAccount: {
        accountName: paymentDetails.accountHolderName,
        accountNumber: paymentDetails.accountNumber,
        sortCode: sortCodeValueToString(paymentDetails.accountSortCode),
      },
    },
    quote: {
      pets: quote.petInfos.map(() => ({
        cover: petCovers || [],
      })),
      party: {
        customerId: customerInfo.customerId || '',
        email: customerInfo.email,
        firstName: customerInfo.firstName,
        surname: customerInfo.lastName,
      },
    },
  };
};

export default mapMonthlyConfirmationPolicyMovementDetails;
