import ErrorContainer from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer';
import { ContentSpacing } from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer/styles';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import { ErrorPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled, { css } from 'styled-components';
import SectionHeadingWithIcons from 'components/Payment/SectionHeadingWithIcons';
import QuestionField from 'components/QuestionField';
import { HeadingsWrapper } from '../../SectionHeadingWithIcons/styles';

export const StyledSectionHeadingWithIcons = styled(SectionHeadingWithIcons)`
  h2 {
    font-weight: ${semiBoldFontWeight};
  }

  ${HeadingsWrapper} {
    width: auto;
  }

  margin-bottom: ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const ErrorText = styled.p`
  ${fonts.paragraph};
  margin: 0;
`;

export const ErrorPanelWithMargin = styled(ErrorPanel)`
  margin: ${spacing(1)} 0 ${spacing(2)};
`;

export const BankDetailsPanel = styled(SimplePanel)`
  margin: ${spacing(1)} 0;
  padding: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)};
  `}
`;

export const BankDetailsHeading = styled.p`
  ${fonts.headingXSmall}
  margin: 0;
  text-align: center;
`;

export const BankDetailsInfo = styled(BankDetailsHeading)`
  ${fonts.paragraphLarge}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(1.5)};
  `}
`;

export const MonthlyPaymentDateQuestionField = styled(QuestionField)`
  margin-bottom: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const StyledErrorContainer = styled(ErrorContainer)`
  margin-bottom: ${spacing(0)};
  ${({ errorText }) =>
    errorText !== undefined &&
    css`
      margin: 0 0 ${spacing(2)};
      padding: 0;
      ${mediaQuery.tabletLandscape`
          margin-bottom: ${spacing(3)};
        `};
      ${ContentSpacing} {
        margin: ${spacing(1)} ${spacing(2)} 0;
      }
    `}
`;
