import Image from '@rsa-digital/evo-shared-components/components/Image';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledImage = styled(Image)`
  height: ${spacing(4)};
  width: ${spacing(6)};
  margin-top: ${spacing(1)};
  margin-right: ${spacing(2)};
`;
