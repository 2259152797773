import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { InfoPanel } from 'components/StatusPanel';

export const SignpostInfoPanel = styled(InfoPanel)`
  margin: ${spacing(4)} 0 0;
  padding: ${spacing(3)} ${spacing(3)} ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)} ${spacing(3)} ${spacing(3)} ${spacing(3)};
  `}

  ${IconWrapper} {
    height: ${spacing(3)};
    width: ${spacing(3)};

    ${mediaQuery.tabletLandscape`
        margin: 0 ${spacing(1)} 0 0;
      `}
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h3`
  margin: 0 0 ${spacing(2)};

  ${fonts.headingXSmall}
`;
